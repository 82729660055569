import styled, { css } from 'styled-components';
import SearchIcon from '../icons/SearchIcon';
import { StyledClearButton, StyledSearchProps } from './Search.types';
import { getColor } from '../colors';
import { LabelMediumStyles } from '../Typography/Typography.styles';

export const SearchInputContainer = styled.div<StyledSearchProps>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '400px')};
  height: 40px;
  border-radius: 100px;

  ${({ cConf, focused, disabled, dropdown, noFocus }) => {
    if (disabled)
      return css`
        box-shadow: inset 0 0 0 1px ${cConf.disabled.border};
        background: ${cConf.disabled.background};
      `;

    if (dropdown)
      return css`
        background: ${cConf.dropdown.background};
        height: 56px;
      `;

    if (focused && !noFocus)
      return css`
        box-shadow: inset 0 0 0 2px ${cConf.focused.border};
        background: ${cConf.focused.background};
      `;

    return css`
      box-shadow: inset 0 0 0 1px ${cConf.normal.border};
      background: ${cConf.normal.background};
    `;
  }};
`;

export const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  vertical-align: middle;
  left: 24px;
`;

export const SearchInput = styled.input<StyledSearchProps>`
  all: unset;
  ${LabelMediumStyles};

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 18px 50px;

  color: ${({ cConf, focused, disabled, dropdown }) => {
    if (disabled) return cConf.disabled.text;
    if (dropdown) return cConf.dropdown.text;
    if (focused) return cConf.focused.text;
    return cConf.normal.text;
  }};

  ::placeholder {
    color: ${({ cConf, focused, disabled, dropdown }) => {
      if (disabled) return cConf.disabled.placeholder;
      if (dropdown) return cConf.dropdown.placeholder;
      if (focused) return cConf.focused.placeholder;

      return cConf.normal.placeholder;
    }};
    opacity: 1;
  }

  &[disabled] {
    pointer-events: none;
    user-select: none;
  }
`;

export const ClearButton = styled.div<StyledClearButton>`
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 50%;
  color: ${getColor('primary1')};
  background: ${getColor('primary2')};
  right: ${({ dropdown }) => (dropdown ? '24px' : '9px')};
  width: 24px;
  height: 24px;
`;
